
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/um/thjonusta/vefthjonusta",
      function () {
        return require("private-next-pages/um/thjonusta/vefthjonusta.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/um/thjonusta/vefthjonusta"])
      });
    }
  