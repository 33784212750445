import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Row, Title } from "@components/ui";
import BorderedContainer from "@components/ui/bordered_container";
import classNames from "classnames";
import { FlexContainer } from "@components/ui/flex_container";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { THEME_COLOR } from "@utils/constants";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function InfoSection({
    hidden,
    title,
    subtitle,
    children,
    collapsible,
    collapsed,
    titleSize,
    icon,
    items,
    renderItem,
    horizontal,
    style,
    containerStyle,
    complementaryComponent,
    titleTextColor = "#000",
    className,
    containerClassName,
    disabledOverlay,
    disabledOverlayReason,
    gap,
    onItemsReordered = () => {},
    draggable,
    ...props
}) {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);
    const [hasAddedDraggableId, setHasAddedDraggableId] = useState(false);

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        let updatedList = [...items];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        onItemsReordered(updatedList);
    };

    if (hidden) {
        return <></>;
    }

    return (
        <div
            {...props}
            style={{
                marginBottom: 20,
                ...style,
            }}
            className={className}
        >
            {disabledOverlay && (
                <ReactTooltip
                    place="top"
                    id="tooltip-info-is"
                    style={{
                        backgroundColor: THEME_COLOR,
                        borderRadius: 900,
                        color: "white",
                        padding: "5px 15px",
                        boxShadow: "0 0 5px #0000000a",
                        zIndex: 49,
                    }}
                />
            )}
            <div
                className="d-flex flex-row align-items-end justify-content-between flex-wrap"
                style={{ cursor: collapsible ? "pointer" : null }}
                onClick={() => {
                    setIsCollapsed(!isCollapsed);
                }}
            >
                <div className="d-flex flex-row">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                        <span className="align-text-bottom">
                            {collapsible &&
                                (isCollapsed ? (
                                    <FontAwesomeIcon icon={faChevronDown} color={titleTextColor} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronUp} color={titleTextColor} />
                                ))}
                        </span>
                    </div>

                    {icon && (
                        <img src={icon} style={{ width: 30, height: 30, marginRight: 5 }}></img>
                    )}
                    <FlexContainer column>
                        <Title
                            size={titleSize ? titleSize : 5}
                            style={{
                                margin: 0,
                                paddingLeft: 10,
                                color: titleTextColor,
                            }}
                            className="company-location-group-title"
                        >
                            {title}
                        </Title>
                        {subtitle && (
                            <Title
                                size={6}
                                style={{
                                    margin: 0,
                                    paddingLeft: 10,
                                    color: titleTextColor + "ff",
                                    fontWeight: 400,
                                }}
                                className="company-location-group-title"
                            >
                                {subtitle}
                            </Title>
                        )}
                    </FlexContainer>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: 5,
                        flexGrow: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    {complementaryComponent}
                </div>
            </div>
            <Collapse in={!collapsible || !isCollapsed}>
                <div>
                    {items && renderItem ? (
                        <RenderItems
                            items={items}
                            renderItem={renderItem}
                            handleDrop={handleDrop}
                            draggable={draggable}
                            horizontal={horizontal}
                            gap={gap}
                        />
                    ) : (
                        <RenderChildren
                            containerClassName={containerClassName}
                            containerStyle={containerStyle}
                            disabledOverlay={disabledOverlay}
                            disabledOverlayReason={disabledOverlayReason}
                        >
                            {children}
                        </RenderChildren>
                    )}
                </div>
            </Collapse>
        </div>
    );
}

const RenderItems = ({ items, handleDrop, draggable, renderItem, horizontal, gap }) => {
    if (horizontal) {
        return (
            <FlexContainer style={{ flexWrap: "wrap", gap: gap, flexGrow: 1 }}>
                {items.map((item, index) => {
                    return renderItem(item, index);
                })}
            </FlexContainer>
        );
    }
    return (
        <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="dropId">
                {(provided) => (
                    <>
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <div>
                                {items.map((item, index) => {
                                    console.log(item.draggableId);
                                    return (
                                        <Draggable
                                            key={item.draggableId}
                                            draggableId={item.draggableId}
                                            index={index}
                                            isDragDisabled={!draggable}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {renderItem(item, index, snapshot)}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            </div>
                        </div>
                        {provided.placeholder}
                    </>
                )}
            </Droppable>
        </DragDropContext>
    );
};

const RenderChildren = ({
    containerClassName,
    containerStyle,
    disabledOverlay,
    disabledOverlayReason,
    children,
}) => {
    return (
        <BorderedContainer
            className={classNames(containerClassName, "bordered-container-small-sections")}
            style={{ position: "relative", ...containerStyle }}
        >
            {disabledOverlay && (
                <div
                    data-tooltip-id={"tooltip-info-is"}
                    data-tooltip-content={disabledOverlayReason}
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255,255, 255, 0.6)",
                        borderRadius: 8,
                        zIndex: 48,
                        top: 0,
                        left: 0,
                    }}
                ></div>
            )}
            {children}
        </BorderedContainer>
    );
};
