import React from "react";
import InfoSection from "@components/pages/info/info_section";
import Head from "next/head";
import { WEBPAGE_TITLE } from "@utils/constants";

function ServiceNumber() {
    return (
        <>
            <Head>
                <title key="site_title">Vefþjónusta - {WEBPAGE_TITLE}</title>
            </Head>
            <InfoSection containerStyle={{ padding: 30 }}>
                <p className="text-center">
                    <img
                        src="/img/vefthjonusta.png"
                        alt="Vef&thorn;j&oacute;nusta"
                        style={{ width: "100%" }}
                    />
                </p>
                <p className="text-center">
                    Tengdu hugb&uacute;na&eth;arlausnir fyrirt&aelig;kisins vi&eth; gagnagrunn 1819.
                </p>
                <p className="text-center" style={{ paddingBottom: 15 }}>
                    Hr&ouml;&eth;, &ouml;rugg og hagkv&aelig;m lei&eth; sem sparar &thorn;&eacute;r
                    b&aelig;&eth;i t&iacute;ma og peninga.
                </p>
                <h4>
                    <strong>Allar uppl&yacute;singar</strong>
                </h4>
                <p>
                    Veitir a&eth;gang a&eth; &ouml;llum uppl&yacute;singum sem birtar eru vi&eth;
                    leit &aacute; 1819.is. &THORN;essi &thorn;j&oacute;nusta b&yacute;&eth;ur
                    upp&aacute; fj&ouml;lda m&ouml;guleika fyrir vi&eth;skiptavini og kerfi sem
                    &thorn;eir eru me&eth; innanh&uacute;s til &thorn;ess a&eth; f&aacute;
                    n&aacute;nari uppl&yacute;singar um vi&eth;skiptavini s&iacute;na.
                </p>
                <ul style={{ paddingBottom: 15, listStyleType: "circle" }}>
                    <li>M&aacute;na&eth;argjald: 2.300 kr. &aacute;n vsk.</li>
                    <li>Ver&eth; pr. flettingu: 7 kr. &aacute;n vsk.</li>
                </ul>
                <h4>
                    <strong>Nafn &aacute; n&uacute;mer</strong>
                </h4>
                <p>
                    Veitir a&eth;gang a&eth; n&uacute;meri og nafni sem hentar mj&ouml;g vel fyrir
                    s&iacute;mkerfi fyrirt&aelig;kja.
                </p>
                <ul style={{ paddingBottom: 15, listStyleType: "circle" }}>
                    <li>M&aacute;na&eth;argjald: 2.300 kr. &aacute;n vsk.</li>
                    <li>Ver&eth; pr. flettingu: 2 kr. &aacute;n vsk.</li>
                </ul>
                <h4 className="text-center">
                    <strong>Hva&eth; hentar &thorn;&iacute;nu fyrirt&aelig;ki?</strong>
                </h4>
                <p>
                    Haf&eth;u samband vi&eth; okkur &iacute; s&iacute;ma 546 1819 e&eth;a &iacute;
                    gegnum netfangi&eth; 1819@1819.is og vi&eth; finnum lausn sem hentar ykkar
                    &thorn;&ouml;rfum.
                </p>
                <h4 className="text-center">
                    <strong>Skilmálar</strong>
                </h4>
                <h5>
                    <strong>1. gr. Almenn ákvæði</strong>
                </h5>
                <p>
                    Þessir skilmálar ná til aðgangs að vefþjónustu 1819, sem veitir þjónustukaupanda
                    aðgang að upplýsingum úr símaskrá 1819 í samræmi við ákvæði þessa samnings. Með
                    því að samþykkja skilmálana með „haki“ staðfestir notandi að hann hafi kynnt sér
                    efni þeirra ítarlega og samþykki þá í heild sinni.
                </p>
                <h5>
                    <strong>2. gr. Heimildir og skyldur notanda</strong>
                </h5>
                <p>
                    Þjónustukaupanda er skylt að gera samning um notkun vefþjónustu 1819 áður en
                    miðlun getur hafist. Aðgangur þjónustukaupanda að upplýsingum, og hvers konar
                    úrvinnsla þeirra, er háð gildandi lögum hverju sinni. Þjónustukaupanda er skylt
                    að fara eftir þeim skilmálum sem settir eru um notkun, þ.m.t. vinnslu og meðferð
                    þeirra upplýsinga sem miðlað er til hans. Aðgangur þjónustukaupanda að
                    upplýsingum úr gagnagrunni 1819, er eingöngu til einkanota, án tillits til hvort
                    upplýsingarnar eru fengnar beint úr vefþjónustu 1819 eða í gegnum
                    endursöluaðila. Hvers konar eintakagerð, miðlun og vinnsla upplýsinganna úr
                    hendi þjónustukaupanda er óheimil nema með skriflegu leyfi 1819 - Nýs valkosts
                    ehf. Þjónustukaupanda er meðal annars óheimilt, án skriflegs samþykkis 1819 -
                    Nýs valkosts ehf, að:
                    <ol>
                        <li>
                            afrita, breyta, dreifa, leigja, lána, selja, markaðssetja, gefa út eða
                            framleiða nokkurt efni sem byggir á gagnagrunni 1819
                        </li>
                        <li>
                            vendismíða, afkóða, eða á annan hátt reyna að komast að frumkóða
                            gagnagrunns 1819,
                        </li>
                        <li>
                            nota gagnagrunn 1819 á þann hátt að veittur sé aðgangur með
                            fjöldaniðurhali að upplýsingum úr gagnagrunninum,
                        </li>
                        <li>
                            nota gagnagrunn 1819 að hluta eða í heild til samnýtingar með öðrum
                            vörum og/eða forritum, þ.m.t. leiðsögutækjum og smáforritum,
                        </li>
                        <li>
                            nota gagnagrunn 1819 til að útbúa gagnagrunna, t.d. af stöðum eða öðrum
                            skráningum.
                        </li>
                    </ol>
                    Þjónustukaupanda er kunnugt um og viðurkennir skilyrðislaust að gagnagrunnur
                    1819 njóti verndar sem verk samkvæmt höfundalögum nr. 73/1972, sbr. meðal annars
                    6. og 50. gr. laganna. Þjónustukaupanda er einnig ljóst að gagnagrunnur 1819
                    kann að geyma höfundaréttarlega varið efni sem er verndað samkvæmt almennum
                    rétti höfundaréttar. Samningurinn hefur ekki í för með sér neins konar
                    yfirfærslu á réttindum, þ.m.t. höfundarétti, frá einum samningsaðila til annars.
                    Þjónustukaupanda er ljóst að tilgreindar upplýsingar úr gagnagrunni 1819 og
                    meðferð þeirra geta notið verndar sem persónuupplýsingar samkvæmt lögum nr.
                    90/2018 um persónuvernd og vinnslu persónuupplýsinga. Þjónustukaupandi hefur
                    upplýst að notkun persónuupplýsinga, taki aðgangurinn til slíkra upplýsinga, sé
                    í málefnalegum tilgangi og nauðsynleg til að tryggja örugga persónugreiningu og
                    fullnægi að öðru leyti skilyrðum 8. gr. laga nr. 90/2018, sbr. og 9. gr. laga
                    nr. 90/2018. Er aðgangur þjónustukaupanda að upplýsingum veittur á þeirri
                    forsendu og ber þjónustukaupandi ábyrgð á því að öll meðferð og vinnsla
                    persónuupplýsinga sé í samræmi við lög nr. 90/2018, með síðari breytingum.
                </p>
                <h5>
                    <strong>3. gr. Sérstakir skilmálar</strong>
                </h5>
                <p>
                    Þjónustukaupandi skuldbindur sig til að fylgja siðareglum 1819 - Nýs valkosts um
                    heiðarlega viðskiptahætti, sanngjarna og heiðarlega framkomu við viðskiptavini,
                    og tryggja að upplýsingar úr gagnagrunni 1819 séu aldrei notaðar á þann hátt sem
                    gæti misboðið almenningi eða einstaklingum sem eru skráðir í gagnagrunninn.
                    Þjónustukaupandi skuldbindur sig einnig til að tryggja að upplýsingatækniöryggi
                    hans sé fullnægjandi. Þá samþykkir þjónustukaupandi að fylgja í einu og öllu
                    lögum nr. 81/2003 um fjarskipti, þar með talið 46. gr. laganna sem fjallar um
                    óumbeðin fjarskipti, og lögum nr. 90/2018 um persónuvernd og vinnslu
                    persónuupplýsinga.
                </p>
                <h5>
                    <strong>4. gr. Sérstakir skilmálar</strong>
                </h5>
                <p>
                    Um vanefndir og úrræði vegna þeirra gilda almennar reglur kröfuréttar.
                    Skaðabótaréttur hvors samningsaðila takmarkast þó alltaf við beint tjón, en
                    óbeint tjón, þar með talið tap á samningshagsmunum, er ekki bætt. Þessi
                    ábyrgðartakmörkun gildir ekki ef brotið er gegn trúnaðarákvæði 5. gr. 1819 - Nýr
                    valkostur ber jafnframt ekki bótaábyrgð vegna niðritíma eða ónákvæmni
                    upplýsinga.
                </p>
                <h5>
                    <strong>5. gr. Trúnaður</strong>
                </h5>
                <p>
                    Fyllsta trúnaðar skal gætt varðandi öll gögn og upplýsingar sem falla undir
                    þessa skilmála. Aðilar skuldbinda sig sérstaklega til að tryggja trúnað um hvers
                    kyns gögn og upplýsingar sem þeir fá frá gagnaðila, þar á meðal upplýsingar um
                    stjórnunarlegar, fjárhagslegar eða tæknilegar aðstæður, sem og aðrar upplýsingar
                    er varða rekstur, viðskipti eða atriði sem má ætla að teljist trúnaðarmál.
                    Trúnaðarskylda þessi heldur gildi sínu jafnvel eftir að notkun þjónustunnar er
                    lokið.
                </p>
                <h5>
                    <strong>6. gr. Lausn ágreiningsmála</strong>
                </h5>
                <p>
                    Komi upp ágreiningsefni, sem ekki tekst að leysa úr, skulu þau rekin fyrir
                    Héraðsdómi Reykjavíkur.
                </p>
            </InfoSection>
        </>
    );
}
export default ServiceNumber;
